import React, { Fragment } from 'react';
import { graphql } from "gatsby";

import ExploreScreen from 'components/screens/ExploreScreen/ExploreScreen';

// In theory we could pass in props that we'd fetched via Gatsby's GraphQL
export default ({ data }) => (
  <Fragment>
    <ExploreScreen data={data}/>
  </Fragment>
);

export const query = graphql`
query exploreTopics {
  allStrapiTopic(sort: {fields: order}) {
    edges {
      node {
        strapiId
        Name
        Image {
          publicURL
        }
        Icon {
          publicURL
        }
      }
    }
  }
}
`;
