import React from 'react';

const SearchField = ({searchTerm, updateSearchTerm, ...props}) => {
  return (
    <div className="sm:w-auto lg:w-96 relative" {...props}>
      <form className="w-full">
        <label htmlFor="search-input">
          <input
            name="q"
            id="search-input"
            type="search"
            placeholder="Search by keyword"
            className="focus:outline-none focus:shadow-outline mb-0 bg-gray-100 rounded h-12 pl-12 pr-4 text-gray-800 md:w-full"
            autoComplete="off"
            value={searchTerm}
            onChange={(e) => {updateSearchTerm(e.target.value)}}
            />
            <p className="sr-only">Search</p>
          </label>
        <div className="pointer-events-none absolute inset-y-0 flex items-center px-4 text-gray-800">
          <svg className="w-4 h-4 block" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="8.55325" cy="8.55325" r="7.55325" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M18.8717 18.8719L13.8936 13.8938" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        </div>
      </form>
    </div>
  );
}

export default SearchField;