import React from "react";
import tw from 'twin.macro';

const ContainerDiv = tw.div`
  px-4 m-auto md:max-w-screen-md md:px-6 lg:max-w-screen-lg lg:px-8
`;

export default function Container({ children, ...props }) {
  return (
    <ContainerDiv {...props}>
      {children}
    </ContainerDiv>
  );
}

Container.propTypes = {
};

Container.defaultProps = {
};