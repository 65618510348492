import React from 'react';
import PropTypes from 'prop-types';
import tw from 'twin.macro';

import TopicCard from '../../elements/TopicCard/TopicCard';

const TopicGrid = tw.div`
  grid gap-6 md:grid-cols-2 lg:grid-cols-3 items-start mx-auto w-max
`;

const Topics = ({topics, searchTerm, ...props}) => {

  return (
    <TopicGrid {...props}>

      {topics.reduce( (result, topic) => {
        // if no search term OR search term present and matches topic name, show the topic card
        if (
          !searchTerm ||
          (searchTerm && topic.node.Name.toLowerCase().includes(searchTerm.toLowerCase()))
          ) {
          return result.concat(
            <TopicCard 
              key={`topic_${topic.node.Name}`}
              {...topic.node}
            />
        );
        }
        return result;
      }, [])}

    </TopicGrid>
  );
}

Topics.propTypes = {
  topics: PropTypes.array,
}

Topics.defaultProps = {
  topics: [],
}

export default Topics;