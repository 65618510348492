import React, {useState} from 'react';
import { Helmet } from 'react-helmet';

import Container from "components/elements/Container/Container";
import SearchField from 'components/elements/SearchField/SearchField';
import Topics from 'components/layouts/Topics/Topics';


const ExploreScreen = ({data}) => {
  const [searchTerm, setSearchTerm] = useState('');

  const updateSearchTerm = (newSearchTerm) => {
    setSearchTerm(newSearchTerm);
  }

  return (
    <>
      <Helmet
          title="Explore"
          />
      <Container >
        <div className='flex flex-col space-y-4 sm:space-y-0 sm:flex-row justify-between items-center mt-6 mb-16'>
          <h1 className='text-4xl font-bold font-heading uppercase'>Explore</h1>
          <SearchField searchTerm={searchTerm} updateSearchTerm={updateSearchTerm}/>
        </div>
        <Topics topics={data.allStrapiTopic.edges} searchTerm={searchTerm} />
      </Container>
    </>
  );
};

export default ExploreScreen;